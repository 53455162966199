type Ticket = {
  type: string
  name: string
  type_id: number
  fare: number
}

type Price = {
  total: number
  tickets: Ticket[]
}

export type OrderStatus =
  | 'RESERVED'
  | 'PENDING'
  | 'TRANSPORT'
  | 'CANCELED'
  | 'CONFIRMED'
  | 'COMPLETED'
  | 'NOT_APPEARED'

export const statusToString = (status: OrderStatus) => {
  const rawStatus = {
    RESERVED: '配車待ち',
    PENDING: '予約確認待ち',
    TRANSPORT: '移動中',
    CANCELED: 'キャンセル',
    CONFIRMED: '配車待ち',
    COMPLETED: '降車完了',
    NOT_APPEARED: 'スキップ'
  }
  return rawStatus[status]
}

export type AdditionalPassenger = {
  name: string
  telno: string
  type: number
  ride: boolean
  memo: string
}

// 過去履歴のためのランドマーク情報
export type ReffrenceLandmark = {
  id: number
  name: string
  type: string
  address: string
  lng: number
  lat: number
  area_id: number
  layover_landmark_type?: string
  layover_landmark_id?: string
  layover_landmark_name?: string
  layover_landmark_address?: string
  layover_landmark_location: {
    lat: number
    lng: number
  }
  layover_landmark_area_id: number
  available?: boolean
  date_stop_start?: string
  date_stop_end?: string
  stop_comment?: string
}

export type Order = {
  pickup_planned_location_lng: number
  booking_ip: string
  user_id: number
  pickup_execution_location: any
  status_transport: string
  status_reserved: boolean
  pickup_planned_location: any
  dropoff_planned_location_address: string
  reservation_timeout: any
  dropoff_planned_location_name: string
  pickup_planned_location_lat: number
  pickup_execution_location_lat: number
  pickup_planned_location_address: string
  capacity_requirements: number
  detour_factor: number
  current_status: OrderStatus
  price: Price
  status_pending: boolean
  status_confirmed: boolean
  pickup_execution_location_lng: number
  status_not_appeared: boolean
  pickup_planned_location_name: string
  dropoff_execution_location_lat: number
  booking_agent_id: number
  dropoff_execution_location_lng: number
  additional_passengers: AdditionalPassenger[]
  updated: Date
  created: Date
  id: number
  dropoff_planned_location: any
  status_canceled: boolean
  pickup_earliest: string
  mac_address: string
  pickup_time_window: number
  original_dropoff_latest: string
  dropoff_latest: string
  direct_travel_time: number
  dropoff_execution_location: any
  route_ids: number[]
  dropoff_planned_location_lat: number
  date_planned_type: string
  date_planned_start: string
  date_planned_end: string
  date_execution_start: string
  date_execution_end: string
  original_date_planned_type?: string
  original_date_planned_start?: string
  status_completed: boolean
  tag: string
  dropoff_planned_location_lng: number
  memo: string
  user_phone_number: string
  user_name: string
  ticket_number: string
  pickup_landmark_type: string
  pickup_landmark_id: number
  dropoff_landmark_type: string
  dropoff_landmark_id: number
  coupon_price: number
  layover_origin_order_id: number
  layover_destination_order_id: number
  reffrence_pickup_landmark: ReffrenceLandmark
  reffrence_dropoff_landmark: ReffrenceLandmark
  vehicle_id: number
  vehicle_name: string
  notified: boolean
}

export const getOrders = ($axios: any, the_date: string) => {
  return $axios.$get(`/orders/?date=${the_date}`).then((res: Order[]) => {
    res
      .filter((order: Order) => {
        return order.current_status === 'CONFIRMED'
      })
      .map((order: Order) => {
        return order
      })
    return res
  })
}

export const patchOrder = ($axios: any, orderId: number, params: any) => {
  return $axios.$patch(`/orders/${orderId}`, params)
}

export interface PatchOrderParams {
  memo?: string
  additionalPassengers?: AdditionalPassenger[]
  hidden_superior?: boolean
}
export const patchOrderPassengers = (
  $axios: any,
  orderId: number,
  updatedAttr: PatchOrderParams
) => {
  const patch = []
  if (updatedAttr.memo !== undefined) {
    patch.push({
      op: 'replace',
      path: '/memo',
      value: updatedAttr.memo
    })
  }
  if (updatedAttr.additionalPassengers !== undefined) {
    // const capacity_requirements = updatedAttr.additionalPassengers.filter(
    //   (n) => n.ride === true
    // ).length
    patch.push(
      ...[
        {
          op: 'replace',
          path: '/capacity_requirements',
          // value: capacity_requirements
          value: updatedAttr.additionalPassengers.length
        },
        {
          op: 'replace',
          path: '/additional_passengers',
          value: JSON.stringify(updatedAttr.additionalPassengers)
        }
      ]
    )
  }
  if (updatedAttr.hidden_superior !== undefined) {
    patch.push({
      op: 'replace',
      path: '/hidden_superior',
      value: updatedAttr.hidden_superior
    })
  }
  return patch.length === 0
    ? Promise.resolve({ current_status: 'CONFIRMED' })
    : $axios.$patch(`/orders/${orderId}`, patch)
}
