

























import { computed, createComponent, SetupContext } from '@vue/composition-api'
import moment from 'moment'
import { postDriverReadMessages } from '@/models/api/user'

type Message = {
  id: string
  message: string
  datetime: string
}

export default createComponent({
  setup(_props, context: SetupContext) {
    // ポップアップさせるメッセージ
    const newMessage = computed<Message | null>(() => {
      // Bindしたメッセージから getterで絞って取得
      const messages = context.root.$store.getters['message/getRecentMessages']
      // 件数があれば、先頭を取り出し
      if (messages.length > 0) {
        const newTopMessage = messages[0]
        return {
          id: newTopMessage.id,
          message: String(newTopMessage.message),
          datetime: formatDate(newTopMessage.created_at)
        }
      } else {
        return null
      }
    })

    const markAsRead = async () => {
      if (newMessage.value) {
        try {
          const messageId = String(newMessage.value.id)
          console.log('messageId', messageId)
          await context.root.$store.commit(
            'message/markAsClosedMessage',
            messageId
          )
          await postDriverReadMessages(context.root.$axios, messageId)
        } catch (ex) {
          console.log(ex)
        }
        // Firebaseのメッセージ追加
        const messageTxt = '確認しました'
        context.root.$store.dispatch('message/addMessage', { messageTxt })
      }
    }

    const formatDate = (dateTimeString: any) => {
      const createdAt = moment(dateTimeString.toDate())
      const datetimeFormat = moment().isSame(createdAt, 'day')
        ? 'HH:mm'
        : 'YYYY/MM/DD HH:mm'
      return createdAt.format(datetimeFormat)
    }

    return {
      markAsRead,
      newMessage
    }
  }
})
