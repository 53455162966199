
















































































import { computed, createComponent, SetupContext } from '@vue/composition-api'
import moment from 'moment'
import { postDriverReadMessages } from '@/models/api/user'

type Notification = {
  messageId: string
  sequenceId: number
  datetime: string
  from: string
  content: string
}

export default createComponent({
  setup(_props, context: SetupContext) {
    const formatDate = (dateTimeString: any) => {
      const createdAt = moment(dateTimeString.toDate())
      const datetimeFormat = moment().isSame(createdAt, 'day')
        ? 'HH:mm'
        : 'YYYY/MM/DD HH:mm'
      return createdAt.format(datetimeFormat)
    }

    // 全体配信のみを取得して表示させる。日付だけ成型する
    const notifications = computed<Notification>(() => {
      const rawNotifications =
        context.root.$store.getters['message/getNotifications']
      return rawNotifications.map((msg: any) => ({
        messageId: msg.id,
        sequenceId: msg.created_at.seconds,
        datetime: formatDate(msg.created_at),
        from: msg.from === 'operator' ? 'オペレーター' : msg.from,
        content: msg.message
      }))
    })

    const deleteMessage = async (messageId: any) => {
      try {
        await context.root.$store.commit('message/markAsReadMessage', messageId)
        await postDriverReadMessages(context.root.$axios, String(messageId))
      } catch (ex) {
        console.log(ex)
      }
    }
    return {
      notifications,
      deleteMessage
    }
  }
})
