






























































































































































































































































import { computed, createComponent, ref, onMounted } from '@vue/composition-api'
import moment from 'moment'
import { Driver, getDrivers } from '~/models/driver'
import { Order } from '~/models/order'
import {
  DailyReports,
  VehicleDailyReports,
  getVehicleDailyReports,
  getDailyReports
} from '~/models/dailyreports'
import { Vehicle, getAllVehicles } from '~/models/vehicle'

type Header = {
  text: string
  value: string
}

type Contact = {
  id: number
  name: string
  to: string
}

export type Report = {
  v: VehicleDailyReports
  d: DailyReports[]
  sumPassengers: number
  sumPrice: number
}

export default createComponent({
  setup(_props, context) {
    // define some constants
    const orders = ref<Order[]>([])
    const drivers = ref<Array<Driver>>([])
    const vehicles = ref<Vehicle[]>([])
    const driver_name = computed(() => {
      return context.root.$auth.user
        ? `${context.root.$auth.user.last_name}${context.root.$auth.user.first_name}`
        : ''
    })
    const current_vehicle = ref<number>(0)
    const vehicledailyreports = ref<Array<VehicleDailyReports>>([])

    // daily report table headers
    const headers = ref<Array<any>>([
      { text: '回', value: 'seq', width: '1%', align: 'center' },
      {
        text: '乗車時刻',
        value: 'pickup_time',
        width: '8%',
        align: 'center'
      },
      {
        text: '降車時刻',
        value: 'dropoff_time',
        width: '8%',
        align: 'center'
      },
      {
        text: '乗車場所',
        value: 'pickup_place',
        width: '15%',
        align: 'center'
      },
      {
        text: '降車場所',
        value: 'dropoff_place',
        width: '15%',
        align: 'center'
      },
      { text: '利用者名', value: 'user_name', width: '15%', align: 'center' },
      {
        text: '乗客情報',
        value: 'pass_detail',
        width: '18%',
        align: 'center'
      },
      { text: '乗車人数', value: 'passengers', width: '8%', align: 'center' },
      { text: '料金', value: 'price', width: '5%', align: 'center' },
      { text: '備考', value: 'remarks', width: '7%', align: 'center' }
    ])

    const isDataTableLoading = ref<boolean>(false)

    const isPrintable = computed(() => {
      return dailyReports.value.length > 0
    })

    /**
     * check update when date is selected or data is refreshed.
     */
    const checkUpdate = () => {
      // if (!isDataTableLoading.value) {
      fetchVehicleDailyReports()
    }

    const dailyReports = ref<Report[]>([])

    // fetch all daily reports
    const fetchDailyReports = async (v: VehicleDailyReports) => {
      try {
        const d: DailyReports[] = await getDailyReports(
          context.root.$axios,
          v.driver_id,
          v.planned_time.toString(),
          v.vehicle_id
        )
        let sumPassengers = 0
        let sumPrice = 0
        d.forEach((r) => {
          if (r.remarks === '') {
            sumPassengers += r.passengers
            sumPrice += r.price
          }
          // 改行コード対応
          r.pass_detail = r.pass_detail.replace(/\n/g, '<br>')
        })
        dailyReports.value.push({
          v,
          d,
          sumPassengers,
          sumPrice
        })
      } catch (e) {
        console.error(e)
        // context.root.$nuxt.$emit('showSessionErrMessage', true)
      }
    }

    // fetch all daily reports
    const fetchVehicleDailyReports = async () => {
      if (context.root.$auth.user) {
        dailyReports.value.splice(0)
        isDataTableLoading.value = true
        try {
          vehicledailyreports.value = await getVehicleDailyReports(
            context.root.$axios,
            context.root.$auth.user.id,
            date.value,
            current_vehicle.value
          )
          vehicledailyreports.value.forEach((v: VehicleDailyReports) => {
            fetchDailyReports(v)
          })
        } catch (e) {
          console.error(e)
          // context.root.$nuxt.$emit('showSessionErrMessage', true)
        }
        isDataTableLoading.value = false
      }
    }

    const filterByDate = () => {
      // TODO filter routes by date
      return true
    }

    const matchedDates = computed(() => {
      return date.value === moment().format('YYYY-MM-DD')
    })

    const fixTimeFormat = (time: any) => {
      return moment(time).format('HH:mm')
    }

    const date = ref(moment().format('YYYY-MM-DD'))

    // repeat reading
    // const intervalMilliSecond = 30000
    // const fetchingInterval = ref(0)
    // onMounted(() => {
    //   fetchingInterval.value = window.setInterval(async () => {
    //     // await checkUpdate()
    //   }, intervalMilliSecond)
    //   checkUpdate()
    // })
    // onBeforeUnmount(() => {
    //   clearInterval(fetchingInterval.value)
    // })
    const vehicle_lists = computed(() => {
      const values: any = [{ text: 'すべて', value: 0 }]
      vehicles.value.forEach((v) => {
        // if (v.driver_id === current_driver.value) {
        const vv = {
          text: v.name,
          value: v.id
        }
        values.push(vv)
        // }
      })
      values.sort((a: any, b: any) => {
        return a.value - b.value
      })
      return values
    })

    const printDocument = () => {
      const newWindow = window.open(
        'Print',
        '_blank',
        'width=800,height=500,resize=0'
      )
      newWindow!.document.body.innerHTML = document.getElementById(
        'printable'
      )!.innerHTML
      const sheet = document.querySelector('.sheet')
      if (sheet !== null) {
        const divElement = sheet as HTMLDivElement
        // 1頁内に明細を30行（改行無し）を印字できるよう自動で倍率を設定
        divElement.style.transform = 'scale(0.98)'
      }
      newWindow!.window.print()
      newWindow!.close()
    }

    onMounted(async () => {
      if (context.root.$auth.user) {
        isDataTableLoading.value = true
        const [resDrivers, resVehicles] = await Promise.all([
          getDrivers(context.root.$axios).catch(() => []),
          getAllVehicles(context.root.$axios).catch((e: any) => {
            console.log(e)
            context.root.$nuxt.$emit('showSessionErrMessage', true)
          })
        ])
        drivers.value = resDrivers
        vehicles.value = resVehicles
      }
    })
    return {
      headers,
      filterByDate,
      isPrintable,
      date,
      driver_name,
      current_vehicle,
      drivers,
      vehicles,
      vehicledailyreports,
      dailyReports,
      vehicle_lists,
      fetchDailyReports,
      fetchVehicleDailyReports,
      fixTimeFormat,
      orders,
      isDataTableLoading,
      matchedDates,
      checkUpdate,
      printDocument
    }
  },
  auth: false
})
