import { vuexfireMutations, firestoreAction } from 'vuexfire'
import { firestore, Timestamp } from '@/plugins/firestore'

const MESSAGE_COLLECTION_NAME =
  process.env.ctiEnv === 'production' ? 'messages_navi' : 'messages_navi_dev'
const messageRef = firestore.collection(MESSAGE_COLLECTION_NAME)

export const state = () => ({
  loadTimestamp: Date.now(),
  messages: [],
  readMessages: [],
  closedMessages: []
})

export const getters = {
  // メインコンソールのnew_message.vue用
  getRecentMessages: (state) => {
    // 全体配信ではないものかつ、新しいTimestamp以降のみを返し、さらに逆順にする
    return state.messages
      .filter((msg) => {
        return (
          !msg.to.includes('user_all') &&
          !state.closedMessages.find((readmsgId) => readmsgId === msg.id) &&
          msg.created_at.toDate() >= state.loadTimestamp
        )
      })
      .sort((a, b) => a.created_at.seconds - b.created_at.seconds)
  },
  // 運行情報用
  getNotifications: (state) => {
    return state.messages.filter((msg) => {
      return (
        // 全体配信のもののみ返す
        !state.readMessages.find((readmsgId) => readmsgId === msg.id)
      )
    })
  }
}

export const actions = {
  bindMessages: firestoreAction(({ bindFirestoreRef, rootState }) => {
    const ref = messageRef
      .where('to', 'array-contains-any', [
        'user_all',
        'driver' + rootState.auth.user.id
      ])
      .orderBy('created_at', 'desc')
    return bindFirestoreRef('messages', ref)
  }),
  addMessage: async ({ rootState }, { messageTxt }) => {
    const from = 'driver' + rootState.auth.user.id || ''
    const to = ['operator']
    try {
      await messageRef.add({
        from,
        to,
        message: messageTxt,
        created_at: Timestamp.now()
      })
    } catch (err) {
      console.log(err)
    }
  }
}

export const mutations = {
  // Vuexfireのミューテーションを追加して bindmethodを呼出し
  ...vuexfireMutations,
  // ポップアップ表示
  markAsClosedMessage(state, message) {
    state.closedMessages.push(message)
  },
  // リスト表示
  markAsReadMessage(state, message) {
    if (Array.isArray(message)) {
      // APIからは配列で受信するため分解する
      state.readMessages.push(...message)
    } else {
      state.readMessages.push(message)
    }
  },
  prepareTimestamp(state) {
    state.loadTimestamp = Date.now()
  },
  // stateを初期状態にリセットするミューテーション
  resetState(state) {
    Object.assign(state, {
      messages: [],
      readMessages: [],
      closedMessages: []
    })
  }
}
