import moment from 'moment'
import { Route } from './route'

export type Vehicle = {
  make: string
  current_status: Status
  current_cargo: number
  current_driver: string
  created: Date
  live_location: any
  license_plate: string
  next_stop_address: string
  phone_number: string
  id: number
  next_stop_time: string
  km: number
  name: string
  model: string
  updated: Date
  capacity: number
  current_order: number
  order_type: number
  depot_id: string
  driver_id: number
}

export type Status = 'PREACTIVE' | 'ACTIVE' | 'ONROUTE' | 'INACTIVE' | 'REST'
export const VEHICLE_STATUSES = {
  PREACTIVE: '待機', // 車両選択済、運行便未選択
  ACTIVE: '待機', // 運行便選択済
  ONROUTE: '運行中',
  INACTIVE: '運行終了',
  REST: '休憩'
}

export const statusToString = (status: Status) => {
  return VEHICLE_STATUSES[status]
}

export const getAllVehicles = (axios: any) => {
  return axios.$get(`/vehicles/?date=${moment().format('YYYY-MM-DD')}`)
}

export const getVehiclesAvailabilities = (
  $axios: any,
  vehicleId: number,
  the_date: string
) => {
  return $axios.$get(`/vehicles/${vehicleId}/availabilities/?date=${the_date}`)
}

export const getVehicle = ($axios: any, id: number): Promise<Vehicle> => {
  return $axios.$get(`/vehicles/${id}`)
}
